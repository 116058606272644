export const environment = {
  production: true,
  apiUrl: 'https://mdcompbackend-stg.protiviti.com',
  webUrl: 'https://mdcomp-stg.protiviti.com',
  msalConfig: {
    auth: {
      clientId: '043f2532-cda3-432f-a76b-1bcf0f6b5620',
      authority: 'https://login.microsoft.com/16532572-d567-4d67-8727-f12f7bb6aed3'
    }
  },
  apiConfig: {
    scopes: ['api://8488b249-58b4-410f-8320-4a6a54a99e3b/user_impersonation'],
    uri: '/',
  },
};
